export const PARTNER_LIST = [
  {
    name: "台山市阳光社会工作综合服务中心",
    logo: require("@/assets/partner/logo_1.jpeg")
  },
  {
    name: "武汉市东西湖区予童社会工作服务中心",
    logo: require("@/assets/partner/logo_2.png")
  },
  {
    name: "武汉市阳光社会工作服务中心",
    logo: require("@/assets/partner/logo_3.jpeg")
  },
  {
    name: "郑州市金水区同行社会工作服务中心",
    logo: require("@/assets/partner/logo_4.jpeg")
  },
  {
    name: "淄博束氏庄园公益中心",
    logo: require("@/assets/partner/logo_5.jpeg")
  },
  {
    name: "郑州市中原区德佑社会工作服务中心",
    logo: require("@/assets/partner/logo_6.jpeg")
  },
  {
    name: "广州市协和社会工作服务中心",
    logo: require("@/assets/partner/logo_7.jpeg")
  },
  {
    name: "海盐县民联社工服务中心",
    logo: require("@/assets/partner/logo_8.jpeg")
  },
  {
    name: "惠州市爱之声社会工作发展中心",
    logo: require("@/assets/partner/logo_9.jpeg")
  },
  {
    name: "柳州市春晖社会工作服务中心",
    logo: require("@/assets/partner/logo_10.jpeg")
  },
  {
    name: "泸州市江阳区恒德社会工作服务中心",
    logo: require("@/assets/partner/logo_11.jpeg")
  },
  {
    name: "北京睦友社会工作发展中心",
    logo: require("@/assets/partner/logo_12.jpeg")
  },
  {
    name: "成都市郫都区崇宁社会工作服务中心",
    logo: require("@/assets/partner/logo_13.jpeg")
  },
  {
    name: "成都市青白江区义工联合会",
    logo: require("@/assets/partner/logo_14.jpeg")
  },
  {
    name: "成都市武侯区心航社会工作服务中心",
    logo: require("@/assets/partner/logo_15.jpeg")
  },
  {
    name: "成都天府新区公艺派社会工作服务中心",
    logo: require("@/assets/partner/logo_16.jpeg")
  },
  {
    name: "赤峰爱氧星志愿服务发展协会",
    logo: require("@/assets/partner/logo_17.jpeg")
  },
  {
    name: "彭州市仁爱社会工作服务中心",
    logo: require("@/assets/partner/logo_18.jpeg")
  },
  {
    name: "兰州市西固区爱如阳光社会工作服务中心",
    logo: require("@/assets/partner/logo_19.jpg")
  },
  {
    name: "达州市万家和社会工作服务中心",
    logo: require("@/assets/partner/logo_20.png")
  },
  {
    name: "眉山市彭山区友仁公益发展协会",
    logo: require("@/assets/partner/logo_21.png")
  },
  {
    name: "成都市金堂心翼社工站",
    logo: require("@/assets/partner/logo_22.png")
  },
  {
    name: "沈阳市青春志愿者协会",
    logo: require("@/assets/partner/logo_23.png")
  },
  {
    name: "遂宁春晖社会工作发展中心",
    logo: require("@/assets/partner/logo_24.jpg")
  },
  {
    name: "雅安市雨城区敏睿博爱公益服务中心",
    logo: require("@/assets/partner/logo_25.jpg")
  },
  {
    name: "成都市龙泉驿区有邻社会工作服务中心",
    logo: require("@/assets/partner/logo_26.png")
  }
]