<template>
  <div class="support-container main-box">
    <commonTitle :title="title" :subtitle="subTitle"></commonTitle>
    <div class="support-list">
      <el-carousel trigger="click" height="4.8rem" arrow="hover" :autoplay="false">
        <el-carousel-item v-for="(item, index) in supportList" :key="index">
          <div class="support-swiper flex">
            <div class="support-img-item" v-for="(cur, curIndex) in item" :key="curIndex">
              <div class="support-img-content">
                <img :src="cur.imgUrl" class="support-img" @click="goDetail(cur, curIndex, index)"/>
              </div>
              <div class="support-name fs-12 fc-3">{{cur.name}}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import commonTitle from "./commonTitle";
import { SUPPORT_LIST } from "@/static/supportList";

export default {
  name: "support",
  components: {
    commonTitle
  },
  data() {
    return {
      title: "区域支持中心",
      subTitle: "REGIONAL SUPPORT CENTER",
      supportList: SUPPORT_LIST
    };
  },
  mounted() {},
  methods: {
    goDetail(cur, curIndex, index) {
      this.$router.push({
        path: `/supportDetail?supportName=${cur.name}&index=${curIndex}&preIndex=${index}`
      });
    }
  }
};
</script>

<style lang="less" scoped>
.support-container {
  .support-list {
    .support-swiper {
      justify-content: space-between;
      width: 100%;
      height: 76%;
      .support-img-item {
        text-align: center;
        cursor: pointer;
        .support-img-content {
          width: 1.9rem;
          height: 1.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0.2rem;
          .support-img {
            width: 100%;
          }
        }
      }
    }
  }
}
/deep/ .el-carousel__container {
  .el-carousel__arrow {
    background-color: #7c7c7caa;
    width: 0.6rem;
    height: 0.6rem;
    i {
      font-size: 0.4rem;
    }
  }
}
/deep/ .el-carousel__indicator.el-carousel__indicator--horizontal {
  .el-carousel__button {
    background-color: #7c7c7c;
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 0.1rem;
  }
  &.is-active {
    .el-carousel__button {
      background-color: var(--common-red);
      width: 0.14rem;
      height: 0.14rem;
      border-radius: 0.14rem;
    }
  }
}
</style>