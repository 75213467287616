<template>
  <div class="footer-container">
    <el-popover
      popper-class="popper"
      placement="left"
      width="100"
      trigger="hover"
      v-show="isShowScrollBtn"
    >
      <el-button icon="el-icon-arrow-up" slot="reference" type="info" plain @click="scrollTop"></el-button>
      <p class="login-text">回到顶部</p>
    </el-popover>
    <div class="footer-one flex">
      <div class="img-content flex">
        <img src="@/assets/footer_logo.png" class="footer-logo" />
      </div>
      <div class="connect">
        <div class="fs-12 fc-f text">客服信箱：{{email}}</div>
        <div class="fs-12 fc-f text">服务热线：{{tel}}</div>
      </div>
      <div class="qr flex">
        <div class="qr-item flex" v-for="(item, index) in qrList" :key="index">
          <img :src="item.imgUrl" class="qr-img" />
          <div class="name fs-12 fc-f">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="footer-two flex">
      <div class="company fs-12 fc-1" v-for="(item, index) in companyList" :key="index">
        <a v-if="index === 3" href="https://beian.miit.gov.cn">{{ item }}</a>
        <span v-else>{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "footers",
  components: {},
  data() {
    return {
      email: "yicangfzwl2017@foxmail.com",
      tel: "13540277695",
      qrList: [
        {
          name: "微信公众号",
          imgUrl: require("@/assets/qr_wx.png")
        },
        {
          name: "米宝客服",
          imgUrl: require("@/assets/qr_kefu.png")
        }
      ],
      companyList: [
        "主办：义仓发展网络",
        "项目支持：南都公益基金会、壹基金",
        "技术支持：成都三边法则科技有限公司",
        "蜀ICP备2020031558号-1"
      ],
      isShowScrollBtn: false
    };
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  methods: {
    onScroll() {
      var distance = document.documentElement.scrollTop;
      this.isShowScrollBtn = distance > 500 ? true : false;
    },
    scrollTop() {
      $("html, body").animate(
        {
          scrollTop: 0
        },
        1000
      );
    }
  }
};
</script>

<style lang="less">
.footer-container {
  .el-popover__reference-wrapper {
    position: fixed;
    right: 0.2rem;
    bottom: 0.1rem;
  }
}
</style>
<style lang="less" scoped>
.popper {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  .login-text {
    font-size: 0.14rem;
    color: #111;
    text-align: center;
  }
}
.footer-container {
  .el-popover__reference-wrapper {
    position: fixed !important;
    right: 1rem;
    bottom: 1rem;
  }
}
.footer-one {
  background-color: var(--common-red);
  justify-content: space-between;
  padding: 0.17rem 2.7rem;
  .img-content {
    flex: 1;
    .footer-logo {
      width: 1rem;
      height: 1rem;
    }
  }
  .connect {
    background-color: #00000022;
    padding: 0.24rem;
    height: 1.24rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 0.1rem;
  }
  .qr {
    background-color: #00000022;
    padding: 0 0.24rem;
    height: 1.24rem;
    .qr-item {
      flex-direction: column;
      justify-content: center;
      &:first-child {
        margin-right: 0.18rem;
      }
      .qr-img {
        width: 0.68rem;
        height: 0.68rem;
      }
    }
  }
}

.footer-two {
  background-color: #dbbb85;
  justify-content: space-between;
  padding: 0.18rem 2.5rem;
}
a {
  text-decoration: none;
  color: #333;
}
</style>