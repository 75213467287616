<template>
  <div class="block banner-container">
    <el-carousel trigger="click" height="4.8rem" arrow="hover">
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <img :src="item.cover" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import request from "@/utils/request";
import { API } from "@/const/const";

export default {
  name: "banner",
  data() {
    return {
      dataList: sessionStorage.getItem("indexBanner")
        ? JSON.parse(sessionStorage.getItem("indexBanner"))
        : []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      request.get(API.GET_BANNERS_LIST, { type: 2 }).then(res => {
        this.dataList = res.banners;
        sessionStorage.setItem("indexBanner", JSON.stringify(res.banners));
      });
    }
  }
};
</script>
 
<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}
/deep/ .el-carousel__container {
  .el-carousel__arrow {
    width: 0.6rem;
    height: 0.6rem;
    i {
      font-size: 0.4rem;
    }
  }
}
/deep/ .el-carousel__indicator.el-carousel__indicator--horizontal {
  .el-carousel__button {
    opacity: 0.4;
    width: 0.2rem;
    height: 0.06rem;
    border-radius: 0.06rem;
  }
  &.is-active {
    .el-carousel__button {
      opacity: 1;
      width: 0.36rem;
      height: 0.06rem;
    }
  }
}
</style>
