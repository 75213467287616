export const BRAND_LIST = [
  {
    name: `义仓`,
    miniName: `yc`,
    active: false,
    imgUrl: require("@/assets/brand/icon_yicang.png"),
    html: `<p style='font-size: 24px'>义仓项目介绍</p><p style="line-height: .3rem">“义仓”简言之即是一个爱心仓库，它倡导社区内的居民持续性地定期将小额生活物资捐赠到“义仓”，也鼓励居民捐赠时间提供志愿服务。居民志愿者将这些爱心物资送到社区内有需要的困难家庭中，在为困难家庭带去生活必需的物资同时，也传递温暖和关怀。而接受物资资助的受助家庭可以通过时间的捐赠成为义仓的爱心家庭，因此义仓中没有绝对的受助与施助，每个人都生活在社区的共同体中，每个人用自己的方式参与社区建设。每个人都能在义仓中感受到尊重、平等。</p>`,
    imgList: [{ des: "义仓物资接收—贴条形码", src: require("@/assets/brand/yicang_1.png") }, { des: "义仓志愿者物资派发", src: require("@/assets/brand/yicang_2.png") }, { des: "义仓志愿者与受助家庭合影", src: require("@/assets/brand/yicang_3.png") }]
  },
  {
    name: `义集`,
    miniName: `yj`,
    active: false,
    imgUrl: require("@/assets/brand/icon_yiji.png"),
    html: `<p style='font-size: 24px'>义集项目介绍</p><p style="line-height: .3rem">义集是基于对义仓物资的最大化使用的思考而产出的，立足社区户外公共空间资源和社群组织，整合资源建立爱心企业联盟和爱心家庭联盟，核心在于搭建社区资源和邻里交流平台，用定期集市的方式促进多元力量的社会参与，不仅对困难人群需求的回应，同时营造社区，构建互信互助的邻里文化，打造成为一个社区或者小区的的标志社区文化性景观或者集体生活的节日。</p>`,
    imgList: [{ des: "义集活动合影", src: require("@/assets/brand/yiji_1.png") }, { des: "义集活动现场", src: require("@/assets/brand/yiji_2.png") }]
  },
  {
    name: `义仓日`,
    miniName: `ycr`,
    active: false,
    imgUrl: require("@/assets/brand/icon_yicangri.png"),
    html: `<p style='font-size: 24px'>义仓日项目介绍</p><p style="line-height: .3rem">义仓日，是义仓实践者集体行动并发起邻里互助倡议的节日。在每年秋分，发起关注“邻里关系疏离，社区公共生活匮乏”的倡导，以嘉年华的形式，通过义演、义卖、义诊、义捐，以及其他体验式、互动的趣味活动，加强公众对邻里互助的认识，激发更多的居民参与社区公共生活，让更多人在活动气氛中感受、体验、激活邻里的互助友好关系。</p>`,
    imgList: [{ des: "你好，邻居-义仓日", src: require("@/assets/brand/yicangri_1.jpg") }]
  },
  {
    name: `义仓小站`,
    miniName: `ycxz`,
    active: false,
    imgUrl: require("@/assets/brand/icon_yicangxiaozhan.jpg"),
    html: `<p style='font-size: 24px'>义仓小站项目介绍</p><p style="line-height: .3rem">一、义仓小站是什么</p><p style="line-height: .3rem">义仓小站是义仓发展网络领域邻里互助及社区帮扶老弱的品牌项目，项目以中国传统慈善文化为基底，支持让社区更温暖的邻里互助行动者搭建参与式社区互助体系，重点服务于社区老弱人群，推动“困难帮扶、志愿服务、社群培育、资源共享、项目示范”的标准化、生活化、社群化的社区互助空间。</p><p style="line-height: .3rem">二、义仓小站服务对象</p><p style="line-height: .3rem">直接服务对象：社区老弱人群，包括孤寡、残障、贫困、病弱、独居、空巢、高龄无保障等人群</p><p style="line-height: .3rem">间接服务对象：儿童、社区商家、在地企业、学校等</p><p style="line-height: .3rem">三、义仓小站关注的人群主要问题是什么</p><p style="line-height: .3rem">邻里互助及老弱人群日常生活保障及照顾</p><p style="line-height: .3rem">四、义仓小站主要服务是什么</p><p style="line-height: .3rem">1.小额生活物资性帮扶</p><p style="line-height: .3rem">2.代理代办生活事务</p><p style="line-height: .3rem">3.常态化探访和陪伴</p><p style="line-height: .3rem">4.康乐服务</p><p style="line-height: .3rem">5.报案和转接服务</p><p style="line-height: .3rem">五、义仓小站服务提供模式</p><p style="line-height: .3rem">社工+社区志愿者，物资+服务，社区互助志愿者队伍+公益产品</p>`,
    imgList: []
  },
  {
    name: `一勺米`,
    miniName: `ysm`,
    active: false,
    imgUrl: require("@/assets/brand/icon_yishaomi.png"),
    html: `<p style='font-size: 24px'>一勺米项目介绍</p><p style="line-height: .3rem">一勺米是义仓的子项目，设计灵感来自于百家饭。该项目针对邻里关系疏离的现状，通过邀请社区的儿童及家长以“你好，邻居”公益挑战赛的形式，敲开9户陌生陌生邻居家的门募集一勺米，同时并邀请邻居参与分食百家粥公益活动。以此，推动邻里的认识和互动，激发他们邻里互动的意愿，促进社区社会教育的普遍开展。</p>`,
    imgList: [{ des: "小朋友的一勺米", src: require("@/assets/brand/yishaomi_1.jpg") }, { des: "百家粥分享", src: require("@/assets/brand/yishaomi_2.jpg") }]
  },
  {
    name: `一个观众的剧场`,
    miniName: `jc`,
    active: false,
    imgUrl: require("@/assets/brand/icon_juchang.png"),
    html: `<p style='font-size: 24px'>一个观众的剧场项目介绍</p><p style="line-height: .3rem">一个观众的剧场是义仓的子项目。项目针对社区空巢老人缺少关怀的需求，通过动员社区志愿者，以文艺服务（表演节目）+支持陪伴（唠嗑聊天）的形式，缓解老人的孤独寂寞，促进老人与社会的联结，推动邻里互助及养老发展!</p>`,
    imgList: [{ des: "小朋友志愿者给高龄老人演奏", src: require("@/assets/brand/juchang_1.png") }, { des: "一个观众的剧场合影", src: require("@/assets/brand/juchang_2.png") }]
  }
]