<template>
  <div class="brand-container main-box">
    <commonTitle :title="title" :subtitle="subTitle" :text_1="text_1" :text_2="text_2"></commonTitle>
    <div class="brand-list flex">
      <div
        class="brand-item"
        v-for="(item, index) in brandList"
        :key="index"
        @click="goBrand(item, index)"
      >
        <div class="img-content flex">
          <img :src="item.imgUrl" class="icon" />
        </div>
        <div class="name fs-16 fc-1">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import commonTitle from "./commonTitle";
import { BRAND_LIST } from "@/static/brandList"

export default {
  name: "brand",
  components: {
    commonTitle
  },
  data() {
    return {
      title: "公益品牌",
      subTitle: "CHARITY BRAND",
      text_1: "义仓发展网络专注于“邻里关系疏离、社区公共生活匮乏”支持性网络",
      text_2:
        "旗下拥有6个公益品牌，义仓、义集、义仓日、义仓小站、一勺米、一个观众的剧场",
      brandList: BRAND_LIST
    };
  },
  mounted() {},
  methods: {
    goBrand(item, index) {
      this.$router.push({ path: `/brand?brandName=${item.miniName}&index=${index}` });
    }
  }
};
</script>

<style lang="less" scoped>
.brand-container {
  background-color: var(--main-box-bg);
  padding: 1.4rem 1rem 1.2rem ;
  .brand-list {
    justify-content: space-between;
    padding-bottom: .2rem;
    .brand-item {
      width: 1.72rem;
      height: 1.8rem;
      border-radius: 0.02rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0.16rem 0 0.2rem;
      box-shadow: 0 0.03rem 0.2rem 0 rgba(0, 0, 0, 0.1);
      .img-content {
        width: 1.1rem;
        height: 1.1rem;
        justify-content: center;
        .icon {
          width: 0.8rem;
        }
      }
    }
  }
}
</style>