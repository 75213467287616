import fetch from '../custom-plugin/fetch/index';

function log(url, queries) {
  return data => {
    if (['development', 'test'].includes(process.env.NODE_ENV)) {
      console.group(`请求${url}`);
      console.log('请求参数：', queries);
      console.log('响应', data);
      console.groupEnd();
    }
    return data;
  };
}

function logError(url, queries) {
  return ex => {
    if (['development', 'test'].includes(process.env.NODE_ENV)) {
      console.group(`请求${url}`);
      console.error('请求参数：', queries);
      console.error('响应', ex);
      console.groupEnd();
    }
    throw ex;
  };
}

export default {
  get(url, queries, config) {
    return fetch
      .get(url, queries, config)
      .then(log(url, queries))
      .catch(logError(url, queries))
  },
  post(url, queries, config) {
    return fetch
      .post(url, queries, config)
      .then(log(url, queries))
      .catch(logError(url, queries))
  },
  put(url, queries, config) {
    return fetch
      .put(url, queries, config)
      .then(log(url, queries))
      .catch(logError(url, queries))
  },
};
