/*
 *
 * 将服务端响应的数据，解析为系统内部统一的格式，并进行一些默认处理
 * 1.根据约定，后端业务成功码为200。其他码则会转换成responseError对象。
 */
import { ERROR_DATA_STRUCT, serverErrorMap } from './fetch-error';

const fetchResponseResolve = res => {
  const { data: json = {} } = res;
  const { code = ERROR_DATA_STRUCT, message = serverErrorMap.get(ERROR_DATA_STRUCT), result, token = null, ok = 0, url = null } = json;
  if (ok === 1) {
    /**
     * {
     *   "ok": 1,
     *   "url": ""
     * }
     * 此数据接口仅为文件上传类接口的返回数据
     */
    return Promise.resolve(url);
  }
  if (code === 0) {
    if (token) {
      localStorage.setItem("token", token)
    }
    return Promise.resolve(result);
  }
  const responseError = { code, msg: message };
  return Promise.reject(responseError);
};

export default fetchResponseResolve;
