import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/news',
    name: 'News',
    component: resolve => require(['../views/News.vue'], resolve),
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: resolve => require(['../views/NewsDetail.vue'], resolve),
  },
  {
    path: '/brand',
    name: 'Brand',
    component: resolve => require(['../views/Brand.vue'], resolve),
  },
  {
    path: '/join',
    name: 'Join',
    component: resolve => require(['../views/Join.vue'], resolve),
  },
  {
    path: '/school',
    name: 'School',
    component: resolve => require(['../views/School.vue'], resolve),
  },
  {
    path: '/about',
    name: 'About',
    component: resolve => require(['../views/About.vue'], resolve),
  },
  {
    path: '/mine',
    name: 'Mine',
    component: resolve => require(['../views/Mine.vue'], resolve),
    meta: {
      needLogin: true
    }
  },
  {
    path: '/organizationData',
    name: 'OrganizationData',
    component: resolve => require(['../views/OrganizationData.vue'], resolve),
    meta: {
      needLogin: true
    }
  },
  {
    path: '/applyOrganization',
    name: 'ApplyOrganization',
    component: resolve => require(['../views/ApplyOrganization.vue'], resolve),
    meta: {
      needLogin: true
    }
  },
  {
    path: '/supportDetail',
    name: 'SupportDetail',
    component: resolve => require(['../views/SupportDetail.vue'], resolve),
  },
  {
      path: '/404',
      component: resolve => require(['../views/404.vue'], resolve),
      meta: { title: '404' }
  },
  {
      path: '/403',
      component: resolve => require(['../views/403.vue'], resolve),
      meta: { title: '403' }
  },
  // {
  //     path: '*',
  //     redirect: '/404'
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
