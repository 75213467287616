<template>
  <div class="achievement-container main-box">
    <commonTitle
      :title="title"
      :subtitle="subTitle"
      :text_1="text_1"
      :text_2="text_2"
    ></commonTitle>
    <div class="achievement-detail flex">
      <div
        class="achievement-item"
        v-for="(item, index) in achievementList"
        :key="index"
      >
        <div class="num fs-26 fc-1">
          <v-countup
            :start-value="0"
            :end-value="item.num"
          ></v-countup>
          <span class="unit fs-16 fc-3">{{item.unit}}</span>
        </div>
        <div class="type fs-12 fc-3">{{item.type}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API, ACHIEVEMENT_LIST } from "@/const/const";
import commonTitle from "./commonTitle";

export default {
  name: "achievement",
  components: {
    commonTitle
  },
  data() {
    return {
      title: "项目成效",
      subTitle: "VOLUNTARY SERVICE",
      text_1: "",
      text_2: "服务覆盖超30万个家庭，1000万人次+",
      achievementList: ACHIEVEMENT_LIST
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      request
        .get(API.GET_REPORTS, {
          is_all: true
        })
        .then(res => {
          this.text_1 = `义仓累计招募义仓核心志愿者达${res.employee_count || 0}人，直接帮扶困难家庭${res.help_family_count || 0}个，活动达10000场以上，`;
          this.achievementList.map(item => {
            item.key && (item.num = res[item.key] || 0)
            return item
          })
        });
    }
  }
};
</script>

<style lang="less" scoped>
.achievement-container {
  .achievement-detail {
    background-color: #fbf8f6;
    justify-content: space-between;
    padding: 0.3rem 1rem 0;
    border-radius: 0.08rem;
    flex-wrap: wrap;
    .achievement-item {
      width: 20%;
      padding-bottom: 0.3rem;
      text-align: center;
    }
    .num {
      font-weight: 500;
    }
  }
}
</style>