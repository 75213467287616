<template>
  <div class="nav-container flex">
    <div
      class="nav-item"
      :class="{'active': item.active}"
      v-for="(item, index) in navList"
      :key="index"
      @click="switchNav(item, index)"
    >{{item.name}}</div>
  </div>
</template>

<script>
export default {
  name: "headNav",
  props: {
    currentPage: String
  },
  data() {
    return {
      navList: [
        {
          name: "首页",
          path: "/",
          active: false
        },
        {
          name: "新闻资讯",
          path: "/news",
          active: false
        },
        {
          name: "公益品牌",
          path: "/brand",
          active: false
        },
        {
          name: "加入义仓",
          path: "/join",
          active: false
        },
        {
          name: "义仓学苑",
          path: "/school",
          active: false
        },
        {
          name: "关于我们",
          path: "/about",
          active: false
        }
      ],
    };
  },
  mounted() {
    this.navList.map(item => {
      item.active = item.name === this.currentPage
      return item
    })
  },
  methods: {
    switchNav(item, index) {
      this.$router.push({ path: item.path})
    }
  }
};
</script>

<style lang="less" scoped>
.nav-container {
  background-color: var(--common-red);
  color: #fff;
  font-size: 0.16rem;
  justify-content: space-between;
  flex-wrap: wrap;
  .nav-item {
    padding-bottom: 0.02rem;
    border-bottom: 0.02rem solid #ffffff00;
    cursor: pointer;
    &.active {
      border-bottom: 0.02rem solid #ffffff;
      font-weight: 500;
    }
  }
}
</style>