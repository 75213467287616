<template>
  <div class="home-container">
    <banner></banner>
    <div class="main">
      <news></news>
      <achievement class="m_con wow  slideInUp" data-wow-delay=".7s"></achievement>
      <brand class="m_con wow  slideInUp" data-wow-delay=".7s"></brand>
      <support class="m_con wow  slideInUp" data-wow-delay=".7s"></support>
      <partner class="m_con wow  slideInUp" data-wow-delay=".7s"></partner>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import banner from "./banner";
import news from "./news";
import achievement from "./achievement";
import brand from "./brand";
import support from "./support";
import partner from "./partner";

export default {
  name: "homeIndex",
  components: {
    banner,
    news,
    achievement,
    brand,
    support,
    partner
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  }
};
</script>
