<template>
  <div class="head-container flex">
    <div class="logo-content flex">
      <img src="../../assets/head_logo.png" class="logo" />
      <div class="title">
        <div class="slogan fs-24 fc-2 fw-5">{{slogan}}</div>
        <div class="sub-slogan fs-12 fc-6">{{subSlogan}}</div>
      </div>
    </div>
    <div class="login">
      <div class="login-btn login-child" v-show="!userInfo.avatar" @click="login">登录</div>
      <el-popover popper-class="popper" placement="bottom" width="100" trigger="hover">
        <img
          class="avatar login-child"
          v-show="userInfo.avatar"
          :src="userInfo.avatar || defaultAvatar"
          slot="reference"
        />
        <p class="login-text" @click="goMine">个人中心</p>
        <div class="line"></div>
        <p class="login-text" @click="logout">退出</p>
      </el-popover>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="350px" center :modal="false">
      <div class="qr-code-content" id="QR_CODE_CONTENT">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'

export default {
  name: "yicangHead",
  data() {
    return {
      defaultAvatar: require("@/assets/avatar.png"),
      dialogVisible: false,
      slogan: "义仓发展网络",
      subSlogan: "支持让社区更温暖的邻里互助行动者",
      userInfo: {
        avatar: "",
        name: "",
      }
    };
  },
  mounted() {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
    this.userInfo = userInfo;
  },
  methods: {
    setWxerwma() {
      const url = window.location.href;
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      const wxElement = document.body.appendChild(s);
      wxElement.onload = function() {
        var obj = new WxLogin({
          id: "QR_CODE_CONTENT", // 需要显示的容器id
          appid: "wxc3318b57e617536c", // 公众号appid wx*******
          scope: "snsapi_login", // 网页默认即可
          redirect_uri: encodeURIComponent(url), // 授权成功后回调的url
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: "black", // 提供"black"、"white"可选。二维码的样式
          href: "" // 外部css文件url，需要https
        });
      };
    },
    login() {
      this.dialogVisible = true;
      this.setWxerwma();
    },
    logout() {
      sessionStorage.setItem("userInfo", JSON.stringify({}));
      this.$router.push({ path: "/" });
    },
    goMine() {
      this.$router.push({ path: "/mine" });
    }
  }
};
</script>

<style lang="less" scoped>
.popper {
  .line {
    background-color: #f2f2f2;
    width: 100%;
    height: 0.02rem;
    margin: 0.1rem 0;
  }
  .login-text {
    font-size: 0.14rem;
    color: #111;
    text-align: center;
    cursor: pointer;
  }
}
.head-container {
  justify-content: space-between;
  background-color: #fff;
  .logo-content {
    margin-right: 0.4rem;
  }
  .logo {
    width: 0.53rem;
    height: 0.53rem;
  }
  .title {
    padding-left: 0.12rem;
  }
  .slogan {
    letter-spacing: 0.02rem;
    font-weight: 600;
  }
  .login {
    position: relative;
    height: 0.4rem;
    .login-child {
      position: absolute;
      top: 0.1rem;
    }
    .login-btn {
      width: 0.7rem;
      font-size: 0.14rem;
      color: #fff;
      border-radius: 0.32rem;
      padding: 0rem 0.12rem;
      line-height: 0.32rem;
      text-align: center;
      background-color: var(--common-red);
      cursor: pointer;
    }
    .avatar {
      width: 0.32rem;
      height: 0.32rem;
      border-radius: 0.32rem;
      cursor: pointer;
    }
  }
}
</style>