import axios from 'axios';
import fetchResponseResolve from './fetch-response';
import axiosError2ResponseError from './fetch-error';

const defaultInstanceConfig = {
  enableDefaultResponseResolve: true, //是否开启默认解析模式
  enableDefaultResponseErrorResolve: true, //是否开启默认异常解析模式
};

function toQueryString(object, serializeType) {
  if (!object) return '';
  return Object.keys(object).reduce((ret, item) => {
    const prefix = !ret ? '' : `${ret}&`;
    const before = `${prefix}${encodeURIComponent(item)}=`;
    const curVal = object[item];
    if (!Array.isArray(curVal)) {
      return `${before}${encodeURIComponent(curVal)}`;
    }

    // from key:[1, 2] to key=1&key=2
    if (serializeType === 'array') {
      const params = curVal.reduce((r, d) => `${!r ? '' : `${r}&`}${encodeURIComponent(item)}=${encodeURIComponent(d)}`, '');

      return `${prefix}${params}`;
    }

    // from key:[1, 2] to key=1,2
    return `${before}${encodeURIComponent(curVal.map(d => JSON.stringify(d)).join(','))}`;
  }, '');
}

function responseInterceptor(response) {
  return fetchResponseResolve(response);
}

function responseErrorInterceptor(error) {
  return Promise.reject(axiosError2ResponseError(error));
}

// Global settings
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

const fetch = {
  enableProtect: true,
  enableProtectByGet: true,
  enableProtectByPost: true,
  /**
   * 发起get请求
   * @param url
   * @param queries
   * @param config
   */
  get(url, queries, config) {
    let finallyUrl = url;
    let headers = { token: localStorage.getItem('token'), OpenId: sessionStorage.getItem('OpenId') }
    const newQueries = { ...queries };
    const { enableDefaultResponseResolve, enableDefaultResponseErrorResolve } = Object.assign({}, defaultInstanceConfig, config);

    if (newQueries && Object.keys(newQueries).length > 0) {
      const queryString = toQueryString(newQueries, 'repeat');
      finallyUrl = (finallyUrl || '').indexOf('?') !== -1 ? `${finallyUrl}&${queryString}` : `${finallyUrl}?${queryString}`;
    }

    let axiosObj = axios;
    if (!enableDefaultResponseResolve || !enableDefaultResponseErrorResolve) {
      axiosObj = axios.create();
      axiosObj.interceptors.response.use(
        !enableDefaultResponseResolve ? null : responseInterceptor,
        !enableDefaultResponseErrorResolve ? null : responseErrorInterceptor,
      );
    }
    const requestConfig = {
      method: 'get',
      url: finallyUrl,
      headers,
    };
    return axiosObj(requestConfig);
  },

  /**
   * 发起post请求
   * @param url
   * @param queries
   * @param config
   */
  post(url, queries, config) {
    let headers = { token: localStorage.getItem('token'), OpenId: sessionStorage.getItem('OpenId') }
    const { enableDefaultResponseResolve, enableDefaultResponseErrorResolve } = Object.assign({}, defaultInstanceConfig, config);

    let axiosObj = axios;
    if (!enableDefaultResponseResolve || !enableDefaultResponseErrorResolve) {
      axiosObj = axios.create();
      axiosObj.interceptors.response.use(
        !enableDefaultResponseResolve ? null : responseInterceptor,
        !enableDefaultResponseErrorResolve ? null : responseErrorInterceptor,
      );
    }
    const requestConfig = {
      method: 'post',
      url,
      headers,
      data: queries,
    };
    return axiosObj(requestConfig);
  },

  /**
   * 发起put请求
   * @param url
   * @param queries
   * @param config
   */
  put(url, queries, config) {
    let headers = { token: localStorage.getItem('token'), OpenId: sessionStorage.getItem('OpenId') }
    const { enableDefaultResponseResolve, enableDefaultResponseErrorResolve } = Object.assign({}, defaultInstanceConfig, config);

    let axiosObj = axios;
    if (!enableDefaultResponseResolve || !enableDefaultResponseErrorResolve) {
      axiosObj = axios.create();
      axiosObj.interceptors.response.use(
        !enableDefaultResponseResolve ? null : responseInterceptor,
        !enableDefaultResponseErrorResolve ? null : responseErrorInterceptor,
      );
    }
    const requestConfig = {
      method: 'put',
      url,
      headers,
      data: queries,
    };
    return axiosObj(requestConfig);
  },

  /**
   * 设置默认请求参数
   * @param options
   */
  setDefaults(options) {
    if (options) {
      const keys = Object.keys(options);
      keys.forEach(key => {
        axios.defaults[key] = options[key];
      });
    }
  },

  /**
   * 设置请求拦截器
   * @param onFulfilled
   * @param onRejected
   */
  setRequestInterceptor(onFulfilled, onRejected) {
    return axios.interceptors.request.use(onFulfilled, onRejected);
  },

  /**
   * 设置响应拦截器
   * @param onFulfilled
   * @param onRejected
   */
  setResponseInterceptor(onFulfilled, onRejected) {
    return axios.interceptors.response.use(onFulfilled, onRejected);
  },
};

export default fetch;
