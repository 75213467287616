<template>
  <div class="common-title-container">
    <div class="title fs-30 fc-3">{{title}}</div>
    <div class="sub-title fs-16 fc-5">{{subtitle}}</div>
    <div class="text fs-14 fc-9" v-show="text_1">{{text_1}}</div>
    <div class="text fs-14 fc-9" v-show="text_2">{{text_2}}</div>
  </div>
</template>

<script>
export default {
  name: "commonTitle",
  props: ["title", "subtitle", "text_1", "text_2"]
};
</script>

<style lang="less" scoped>
.common-title-container {
  text-align: center;
  .title {
    font-weight: 500;
  }
  .sub-title {
    padding: 0.04rem 0 0.16rem;
  }
  .text:last-child {
    margin-bottom: .68rem;
  }
}
</style>