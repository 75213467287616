// 处理富文本中的图片
const reWriteContent = content => {
  content = content.replace(/<img/gi, '<img style="width:100%;height:auto;display:block;margin:.1rem 0;" ');
  return content
}

const slicePartnerList = list => {
  const len = list.length;
  const n = 5;
  const lineNum = len % n === 0 ? len / n : Math.floor((len / n) + 1);
  const res = [];
  for (let i = 0; i < lineNum; i++) {
    const temp = list.slice(i * n, i * n + n);
    res.push(temp);
  }
  return res
}

export { reWriteContent, slicePartnerList }