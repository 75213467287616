/*
 * 请求错误类。
 * 1.提供错误码字典
 * 2.将axios错误对象处理为系统统一的ResponseError
 */

const serverErrorMap = new Map([
  [400, '请求错误(400)'],
]);

const defaultServerErrorPrefix = '连接出错';

const ERROR_DATA_STRUCT = 90001;

/**
 * 将axios的错误对象转换成ResponseError对象
 * @param err
 */
function axiosError2ResponseError(err) {
  const code = !!err && err.response ? err.response.status : 999;

  const msg = serverErrorMap.get(code) || `${defaultServerErrorPrefix}(${code})`;
  return { code, msg };
}

export default axiosError2ResponseError;

export { ERROR_DATA_STRUCT, serverErrorMap };
