<template>
  <div class="index-container">
    <div class="head">
      <yicangHead class="yicang-head"></yicangHead>
      <headNav currentPage="首页" class="head-nav"></headNav>
    </div>

    <div class="body">
      <homePage></homePage>
    </div>

    <div class="footer">
      <footerBox></footerBox>
    </div>
  </div>
</template>

<script>
// head
import yicangHead from "../components/header/head";
import headNav from "../components/header/headNav";
// main
import homePage from "../components/container/home/homeIndex";
// footer
import footerBox from "../components/footers/index";

export default {
  name: "Index",
  components: {
    yicangHead,
    headNav,
    homePage,
    footerBox
  },
  methods: {
  }
};
</script>
