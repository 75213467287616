<template>
  <div class="partner-container main-box">
    <commonTitle :title="title" :subtitle="subTitle" :text_1="text_1" :text_2="text_2"></commonTitle>
    <div class="partner-list">
      <el-carousel trigger="click" height="2.8rem" arrow="hover" :autoplay="false">
        <el-carousel-item v-for="(item, index) in partnerList" :key="index">
          <div class="partner-swiper flex">
            <img
              :src="cur.logo"
              class="partner-img"
              v-for="(cur, curIndex) in item"
              :key="curIndex"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import commonTitle from "./commonTitle";
import request from "@/utils/request";
import { API } from "@/const/const";
import { PARTNER_LIST } from "@/static/partnerList";
import { slicePartnerList } from "@/utils/utils";

export default {
  name: "partner",
  components: {
    commonTitle
  },
  data() {
    return {
      title: "成员伙伴",
      subTitle: "PARTNER",
      text_1: "义仓专注于“邻里关系疏离、社区公共生活匮乏”支持性网络，",
      text_2:
        "服务工作方法推广到30个省（直辖市），97个城市，2500+社区，合作伙伴共计188家",
      partnerList: slicePartnerList(PARTNER_LIST)
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      request.get(API.GET_ORGANIZATIONS_LIST).then(res => {
        this.partnerList = slicePartnerList([...PARTNER_LIST, ...res.data]);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.partner-container {
  background-color: var(--main-box-bg);
  .partner-list {
    .partner-swiper {
      display: flex;
      width: 100%;
      height: 76%;
      .partner-img {
        width: 10%;
        margin: 0 5%;
      }
    }
  }
}
/deep/ .el-carousel__container {
  .el-carousel__arrow {
    background-color: #7c7c7caa;
    width: 0.6rem;
    height: 0.6rem;
    i {
      font-size: 0.4rem;
    }
  }
}
/deep/ .el-carousel__indicator.el-carousel__indicator--horizontal {
  .el-carousel__button {
    background-color: #7c7c7c;
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 0.1rem;
  }
  &.is-active {
    .el-carousel__button {
      background-color: var(--common-red);
      width: 0.14rem;
      height: 0.14rem;
      border-radius: 0.14rem;
    }
  }
}
</style>