/* 运行环境 */
const NODE_ENV = (() => {
  const host = location.href.match(/(http[s]?\:\/\/)(.*?)\//)
  const hostNames = {
      'www.ycfzwl.com': 'prod',
      'yicang.3bsoft.top': 'canary',
  }
  return hostNames[host[2]] || 'dev'
})()

/* 请求路径前缀 */
const API_PREFIX = (() => {
  const map = {
    // dev: 'https://56cf746b5730.ngrok.io/api',
    dev: 'https://yc-admin.3bsoft.top/api',
    canary: 'https://yc-admin.3bsoft.top/api',
    prod: 'https://yc-admin.3bsoft.cn/api',
  };
  return map[NODE_ENV] || 'dev';
})();

/* API 接口 */
const API = {
  LOGIN: `${API_PREFIX}/web_login`,  // 登录
  GET_USERINFO: `${API_PREFIX}/members`,  // 获取用户信息
  GET_NEWS_LIST: `${API_PREFIX}/news`,  // 获取新闻列表
  JOIN_US: `${API_PREFIX}/join_us`,  // 获取加入我们数据
  ABOUT_US: `${API_PREFIX}/about_us`,  // 获取关于我们数据
  GET_COUSRSES_LIST: `${API_PREFIX}/courses`,  // 获取课程列表
  GET_BANNERS_LIST: `${API_PREFIX}/banners`,  // 获取广告列表
  GET_REPORTS: `${API_PREFIX}/reports`,  // 获取项目成效
  GET_ORGANIZATIONS_LIST: `${API_PREFIX}/organizations`,  // 获取成员伙伴列表
  GET_MY_COUSRSES_LIST: `${API_PREFIX}/course_details`,  // 获取学员课程列表
  ORGANIZATIONS: `${API_PREFIX}/organizations`,  // 获取机构申请数据 || 申请成为机构伙伴
  POST_IMG: `${API_PREFIX}/files`,  // 文件上传接口
  REPORTS_DATA: `${API_PREFIX}/reports`,  // 获取机构数据 || 上传机构数据
  GET_ORGANIZATIONS_NEW_LIST: `${API_PREFIX}/organizations/new`,  // 获取所属机构列表 || 获取省市数据列表
};

/** 了解义仓渠道数据基础 */
const KNOW_REASON_LIST = [
  {
    categoryId: 1,
    categoryName: "培训"
  },
  {
    categoryId: 2,
    categoryName: "论坛交流会"
  },
  {
    categoryId: 3,
    categoryName: "公众号"
  },
  {
    categoryId: 4,
    categoryName: "媒体报道"
  },
  {
    categoryId: 5,
    categoryName: "新浪微博"
  },
  {
    categoryId: 6,
    categoryName: "社区推荐"
  },
  {
    categoryId: 7,
    categoryName: "其他机构推荐"
  },
  {
    categoryId: 8,
    categoryName: "其他"
  }
]

/** 首页项目成就数据基础格式 */
// todo: 物资相关属性需要后端提供
const ACHIEVEMENT_LIST = [
  {
    key: "employee_count",
    num: 13655,
    unit: "份", 
    type: "捐赠物资"
  },
  {
    key: "employee_count",
    num: 13655,
    unit: "份", 
    type: "在库物资"
  },
  {
    key: "employee_count",
    num: 13655,
    unit: "份", 
    type: "派送物资"
  },
  {
    key: "employee_count",
    num: 13655,
    unit: "名", 
    type: "注册志愿者"
  },
  {
    key: "employee_count",
    num: 13655,
    unit: "名", 
    type: "核心志愿者"
  },
  {
    key: "help_family_count",
    num: 62595,
    unit: "个",
    type: "帮扶困难家庭"
  },
  {
    num: 10000,
    unit: "以上",
    type: "活动场次"
  },
  {
    num: 1000,
    unit: "万以上",
    type: "受益人数"
  },
  {
    key: "organization_count",
    num: 1289,
    unit: "名",
    type: "成员伙伴"
  },
  {
    key: "member_count",
    num: 2390,
    unit: "名",
    type: "学习伙伴"
  }
]

/** 个人中心机构数据基础格式 */
const REPORTS_DATA = [
  {
    name: "机构覆盖社区数量",
    key: "community_count",
    unit: "个",
    monthData: "20",
    yearData: "30",
    total: "100"
  },
  {
    name: "义仓帮扶家庭数量",
    key: "help_family_count",
    unit: "个",
    monthData: "20",
    yearData: "30",
    total: "100"
  },
  {
    name: "义仓入户场次",
    key: "in_home_count",
    unit: "次",
    monthData: "20",
    yearData: "30",
    total: "100"
  },
  {
    name: "义仓物资数量",
    key: "supplies_count",
    unit: "份",
    monthData: "20",
    yearData: "30",
    total: "100"
  },
  {
    name: "义工志愿者人数",
    key: "employee_count",
    unit: "人",
    monthData: "20",
    yearData: "30",
    total: "100"
  },
  {
    name: "一勺米活动场次",
    key: "one_rice_count",
    unit: "次",
    monthData: "20",
    yearData: "30",
    total: "100"
  },
  {
    name: "一个观众的剧场活动场次",
    key: "one_audience_count",
    unit: "次",
    monthData: "220",
    yearData: "320",
    total: "10202"
  },
  {
    name: "社区义集场次",
    key: "duty_set_count",
    unit: "次",
    monthData: "202",
    yearData: "320",
    total: "100"
  },
]

export { API_PREFIX, API, KNOW_REASON_LIST, REPORTS_DATA, ACHIEVEMENT_LIST }