<template>
  <div class="news-container flex main-box">
    <img
      v-show="dataList.length > 0"
      :src="dataList[0].cover"
      class="top-news"
      @click="goDetail(dataList[0])"
    />
    <div class="news-content">
      <div class="news-title flex">
        <div class="news-text fs-24 fc-1">新闻资讯</div>
        <div class="more fs-20 fc-1" @click="seeMore">查看更多</div>
      </div>
      <ul class="news-list">
        <li
          class="news-item fs-16 fc-3 lm-1"
          v-for="(item, index) in dataList"
          :key="index"
          @click="goDetail(item)"
        >
          <span class="point"></span>
          {{item.title}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API } from "@/const/const";

export default {
  name: "news",
  components: {},
  data() {
    return {
      pageSize: 7,
      page: 1,
      dataList: sessionStorage.getItem("indexNews")
        ? JSON.parse(sessionStorage.getItem("indexNews"))
        : []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      request
        .get(API.GET_NEWS_LIST, {
          page_size: this.pageSize,
          page: this.page
        })
        .then(res => {
          this.dataList = res.data;
          sessionStorage.setItem("indexNews", JSON.stringify(res.data));
        });
    },
    seeMore() {
      this.$router.push({ path: `/news` });
    },
    goDetail(item) {
      this.$router.push({ path: `/newsDetail?id=${item.id}` });
    }
  }
};
</script>

<style lang="less" scoped>
.news-container {
  background-color: var(--main-box-bg);
  .top-news {
    width: 4.58rem;
    height: 3.43rem;
  }
  .news-content {
    height: 3.43rem;
    padding-left: 0.36rem;
    flex: 1;
    .news-title {
      border-bottom: 0.01rem solid #cccccc;
      padding-bottom: 0.09rem;
      justify-content: space-between;
      .news-text {
        padding-left: 0.18rem;
        border-left: 0.08rem solid var(--common-red);
      }
      .more {
        cursor: pointer;
      }
    }
    .news-list {
      height: 2.95rem;
      padding-top: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .news-item {
        cursor: pointer;
        list-style: none;
        position: relative;
        padding-left: 0.12rem;
        .point {
          position: absolute;
          left: 0;
          top: 0.1rem;
          width: 0.04rem;
          height: 0.04rem;
          background-color: #333;
          border-radius: 0.04rem;
        }
      }
    }
  }
}
</style>