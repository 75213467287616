import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../src/styles/common.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import $ from "jquery";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import './styles/StepPage.less'
import rem from './utils/rem';
import request from "@/utils/request";
import { API } from "@/const/const";
import 'animate.css'
import VueCountUp from 'vue-countupjs'
import qs from 'qs'

Vue.use(ElementUI)
Vue.use(VueCountUp)
Vue.config.productionTip = false

//钩子函数
router.beforeEach((to, from, next) => {
  const wx_url = window.location.href;
  const parseUrl = qs.parse(wx_url.split('?')[1]);
  // 微信扫码之后页面路径会添加code
  const wechat_authorized_code = parseUrl.code;
  if (wechat_authorized_code) {
    request
      .post(API.LOGIN, {
        code: wechat_authorized_code,
      })
      .then(res => {
        sessionStorage.setItem("userInfo", JSON.stringify(res))
        sessionStorage.setItem('OpenId', res.open_id)
        // 利用code获取userinfo之后，去掉参数重进页面
        window.location = window.location.href.split('#')[0]
      }).catch(err => {
      });
  } else {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || '{}')
    NProgress.start()
    $("html, body").animate(
      {
        scrollTop: 0
      },
      0
    );
    if (to.meta.needLogin) {
      userInfo.name ? next() : next('/403');
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
